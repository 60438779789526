import request from '@/utils/request'
//工作打卡 /api/WorkSignIn/Add
export function add (data) {
    return request({
      url: '/api/WorkSignIn/Add',
      data,
      method: 'post'
    })
  }
  
  // 获取今日打卡信息
export function getTodayList () {
    return request({
      url: '/api/WorkSignIn/TodayList'
    })
  }


  //部门统计
export function getDepartmentData (Date) {
    return request({
      url: `/api/WorkSignIn/GetWorkSignInDepartmentData?Date=${Date}`
    })
  }


  // 个人明细
export function getUserData (departmentName,Date) {
    return request({
      url: `/api/WorkSignIn/GetWorkSignInUserData?departmentName=${departmentName}&Date=${Date}`
    })
  }
  //我打打卡明细
  export function DayList (data) {
    return request({
      url: '/api/WorkSignIn/DayList',
      data,
      method: 'post'
    })
  }  