import request from '@/utils/request'

export function uploadfile (params) {
    return request({
      url: '/api/Upload/h5file',
      data:params,
      method: 'post',
    //   header:{
    //     'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    //   },
    headers: {
        "Content-Type": "multipart/form-data" 
     },
       transformRequest: [
           (params) => params,
       ]

      
    })
  }


  export function uploadfileplus (params,baseDir) {
    return request({
      url: '/api/Upload/h5fileplus?baseDir='+baseDir,
      data:params,
      method: 'post',
    //   header:{
    //     'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    //   },
    headers: {
        "Content-Type": "multipart/form-data" 
     },
       transformRequest: [
           (params) => params,
       ]

      
    })
  }

  export function uploadCustomizedWineNeedImage (params) {
    return request({
      url: '/api/Upload/UploadCustomizedWineNeedImage',
      data:params,
      method: 'post',
    //   header:{
    //     'Content-Type':'application/json;charset=utf-8'  //如果写成contentType会报错
    //   },
    headers: {
        "Content-Type": "multipart/form-data" 
     },
       transformRequest: [
           (params) => params,
       ]

      
    })
  }