<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="工作打卡" left-arrow @click-left="toHome()"
       >
       <template #right>
          <div @click="tomySignin()">
            <div style="float: right; margin-left: 5px; font-size: 15px">
              记录
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >

    <div
      v-if="loading == false && signinList && signinList.length > 0"
      style="margin: 10px"
    >
      今日打卡记录
      <van-list>
        <van-cell v-for="(h, index) in signinList" :key="index">
          <div style="float: left">
            <div>
              <h3>{{ index + 1 }}、</h3>
            </div>
          </div>
          <div style="float: left; margin-left: 4px">
            <h3>{{ h.creationTime }}</h3>
          </div>
        </van-cell>
      </van-list>
    </div>

    <div v-if="!hassign" class="btn-box2">
      <van-field name="uploader" label="打卡图片">
        <template #input>
          <van-uploader
            v-model="fileList"
            multiple
            :max-count="1"
            :after-read="afterReadAudio"
          />
        </template>
      </van-field>

      <van-button
        type="info"
        size="large"
        round
        block
        @click="onSign"
        :disabled="uploading"
        :loading="uploading"  loading-text="图片上传中..."
        >工作打卡</van-button
      >
    </div>
    <div v-if="hassign" class="btn-box">
      <h2 style="text-align: center; color: rgb(157, 29, 34)">您已打卡</h2>
    </div>

    <van-button
      v-if="
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      "
      type="info"
      size="large"
      round
      block
      to="/worksignin/departmentData"
      >打卡统计</van-button
    >
  </div>
</template>

<script>
import { Toast } from "vant";
import { add, getTodayList } from "@/api/workSignin";
import { uploadfileplus } from "@/api/file";
import compress from "image-file-compress";
// import MapLoader from '@/utils/positionLocation'

export default {
  name: "workSignin",
  data() {
    return {
      loading: true,
      hassign: false,
      uploading: false,
      signinData: {
        imageUrl: "",
      },
      signinList: [],
      fileList: [],
    };
  },
  components: {},

  created() {
    this.getTodayList();
    //MapLoader();
  },

  mounted() {},

  methods: {
    toHome() {
      window.location.replace(window.location.origin);
    },
    tomySignin() {
      this.$router.push({ path: "/workSignin/mySignin" });
    },
    afterReadAudio: async function (files) {
      this.uploading = true;
      Toast("图片上传中");
      try {
        compress(files.file, {
          rotate: true,
          zoom: true,
          max_width: 1200,
          max_height:1200,
          output_type: "image/jpg",
        }).then(async (res) => {
          console.log("res", res);

          let formData = new FormData();
          console.log("file", files.file);
          let myfile = new File([res.data], files.file.name);
          console.log("res", res.data);
          formData.append("file", myfile);
          let aresult = await uploadfileplus(formData, "workSignin");

          let url = aresult.data; // Get url from response
          this.signinData.imageUrl = url;

          this.fileList = [];
          this.fileList.push({
            url: url,

            imageFit: "contain",
            previewSize: 80,
          });
          this.uploading = false;
          Toast("图片上传成功");

          //console.log('file',files.file)
          // res.path : <base64>
          // res.data : <blob>
        });
      } catch (error) {
        Toast("图片上传异常");
      }
      
    },
    async onSign() {
      if (!this.signinData.imageUrl || this.signinData.imageUrl == "") {
        Toast("打卡失败，请先上传打卡图片");
        return;
      }
      //   var amapInfo = sessionStorage.getItem("AMapInfo");
      //   if (amapInfo == "SUCCESS") {
      //     this.para.province = sessionStorage.getItem("AMapInfo_province");
      //     this.para.city = sessionStorage.getItem("AMapInfo_city");
      //     this.para.county = sessionStorage.getItem("AMapInfo_district");
      //     this.para.address = sessionStorage.getItem("AMapInfo_address");
      //     this.para.position =
      //       sessionStorage.getItem("AMapInfo_lng") +
      //       "," +
      //       sessionStorage.getItem("AMapInfo_lat");
      //     if (this.para.city == "") {
      //       this.para.city = this.para.province;
      //     }
      //   }

      let result = await add(this.signinData);
      if (result.data.success) {
        Toast("签到成功");
        this.hassign = true;
        this.getTodayList();
      } else {
        Toast("签到失败");
      }
    },
    async getTodayList() {
      this.loading = true;
      let result = await getTodayList({});
      this.loading = false;
      if (result.data.success && result.data.data) {
        this.signinList = result.data.data;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 10px 10px 20px;
}

.acontent {
  padding: 5px 5px 5px 5px;
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
.btn-box {
  padding: 10px 5px 5px 5px;
  margin-top: 150px;
}
.btn-box2 {
  padding: 10px 5px 5px 5px;
  margin-top: 42px;
}
</style>